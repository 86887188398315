(function ($) {
  $('body').each(function () {
    function animateFrom(elem, direction) {
      direction = direction | 1;

      var delay = 0;
      if (window.innerWidth >= 800) {
        if (elem.hasAttribute('data-gsDelay')) {
          delay = parseFloat(elem.getAttribute("data-gsDelay"));
        }
      }

      var x = 0,
        y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromTop")) {
        x = 0;
        y = -100;
      } else if (elem.classList.contains("gs_reveal_fade")) {
        x = 0;
        y = 0;
      }
      gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
        delay: delay,
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    }

    window.addEventListener("DOMContentLoaded", function () {
      gsap.registerPlugin(ScrollTrigger);

      gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
        ScrollTrigger.create({
          trigger: elem,
          once: true,
          onEnter: function () { animateFrom(elem) },
        });
      });

      gsap.utils.toArray(".section-parallax").forEach((section, i) => {
        section.el = section.querySelectorAll(".el-parallax");

        section.el.forEach((el) => {
          let speed = 2;
          if (el.dataset.speed) {
            speed = el.dataset.speed;
          }

          // Do the parallax effect on each section
          if (i) {
            el.style.transform = `translate3d(0px, 0px, 0px`;

            gsap.to(el, {
              y: `${innerHeight / speed}px`,
              ease: "none",
              scrollTrigger: {
                trigger: section,
                scrub: true
              }
            });
          }

          // the first image should be positioned against the top. Use px on the animating part to work with GSAP.
          else {
            el.style.transform = `translate3d(0px, 0px, 0px`;

            gsap.to(el, {
              y: `${innerHeight / speed}px`,
              ease: "none",
              scrollTrigger: {
                trigger: section,
                start: "top top",
                end: "bottom top",
                scrub: true
              }
            });
          }
        });
      });
    });

    $(".badge-wrapper").on('mousemove', function (e) {
      var posX = ((($(this).height()) / 2) - e.offsetY) * (10 / $(this).height());
      var posY = ((($(this).width()) / 2) - e.offsetX) * (-10 / $(this).width());
      $(this).find('.badge').css({
        'transform': 'rotateX( ' + posX + 'deg ) rotateY( ' + posY + 'deg )',
        'box-shadow': ((posY * -1) + 10) + 'px ' + (posX + 10) + 'px 25px rgba(0, 0, 0, 0.15)'
      });
    });

    $(".badge-wrapper").mouseleave(function (e) {
      var $el = $(this).find('.badge');

      $el.removeAttr('style').addClass('hover--ending');

      setTimeout(function () {
        $el.removeClass('hover--ending');
      }, 500);
    });
  });
})(jQuery);
