(function ($) {
  $('.cta-fixed').each(function () {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 30) {
        $('.cta-fixed').addClass('scrolled');
      } else {
        $('.cta-fixed').removeClass('scrolled');
      }
    });
  });
})(jQuery);
