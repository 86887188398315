
(function ($) {
  $(document).on('click', ".toggle-modal", function (e) {
    e.preventDefault();
    var target = $(this).attr("href");

    $('body').addClass('modal-open');
    $(target).addClass("open");

    if ($(target).find(".video-iframe").length) {
      var video = $(target).find(".video-iframe iframe");
      var src = video.attr("src");

      video.attr("src", src + "&autoplay=1");
    }
  });
  $(document).on('click', ".modal .close-modal", function () {
    close();
  });
  $(document).on('click', ".modal", function () {
    close();
  });
  $(document).on('click', ".modal .modal-inner", function (e) {
    e.stopPropagation();
  });
  $(document).keydown(function (e) {
    var code = e.keyCode || e.which;
    if (code == 27) {
      close();
    }
  });

  function close() {
    if ($(".modal.open").find(".video-iframe").length) {
      var video = $(".modal.open").find(".video-iframe iframe");
      var src = video.attr("src");

      video.attr("src", src.replace("&autoplay=1", ""));
    }

    $(".modal.open").removeClass("open");
    $('body').removeClass('modal-open');
  }

  // var popupCookie = Cookies.get('popup_modal');
  // if (popupCookie == undefined) {
  //   setTimeout(function () {
  //     $('body').addClass('modal-open');
  //     $("#popup-modal").addClass("open");
  //   }, 1000);
  //   $(document).on('click', ".modal .close-modal, .modal a", function () {
  //     Cookies.set('popup_modal', 'please_do_not_show_again', { expires: 1 });
  //   });
  //   $(document).on('click', ".modal", function () {
  //     Cookies.set('popup_modal', 'please_do_not_show_again', { expires: 1 });
  //   });
  //   $(document).on('click', ".modal .modal-inner", function (e) {
  //     e.stopPropagation();
  //   });
  // }


})(jQuery);
