
(function ($) {
  $('.smooth-scroll').on("click", function (event) {
    event.preventDefault();

    var target = $(this.hash);

    $('html, body').animate({
      scrollTop: target.offset().top
    }, 1000);
  });
})(jQuery);
